import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, tap } from 'rxjs';
import { HttpPaymentsService } from '../../http-services/http-payments/http-payments.service';
import { PaymentsHttpAdapter } from '../../http-adapters/payments-http-adapter';
import { PaymentTypeMethods } from '../../agreement-keys/payment-type-methods.enum';
import { map } from 'rxjs/operators';
import { StripePaymentService } from '../stripe-payment/stripe-payment.service';
import { PaymentMethod } from '@stripe/stripe-js';
import { IPaymentMethodResponse } from '@sp-core/models/app-models/payment-method-response.interface';
import Utils from '@sp-helpers/utils';
import { ITransaction } from '@sp-core/models/app-models/transaction.interface';
import { IPaymentSubscriptions } from '@sp-core/models/app-models/payment-subscriptions.interface';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  public _currentPaymentTypeMethod$: BehaviorSubject<PaymentTypeMethods> = new BehaviorSubject<PaymentTypeMethods>(
    null,
  );

  constructor(
    public _httpPaymentsService: HttpPaymentsService,
    public _paymentsHttpAdapter: PaymentsHttpAdapter,
    public _stripePaymentService: StripePaymentService,
  ) {}

  public setPaymentTypeMethod(type: PaymentTypeMethods): void {
    this._currentPaymentTypeMethod$.next(type);
  }

  public getPaymentTypeMethod$(): Observable<PaymentTypeMethods> {
    return this._currentPaymentTypeMethod$.asObservable();
  }

  public getPaymentTypeMethodAsValue(): PaymentTypeMethods {
    return this._currentPaymentTypeMethod$.getValue();
  }

  public getPaymentPublicKeyFromStore(): string {
    if (this.getPaymentTypeMethodAsValue() === PaymentTypeMethods.stripe) {
      return this._stripePaymentService.getStripePublicKeyAsValue();
    }
  }

  public getPaymentPublicKey(methodType: PaymentTypeMethods): Observable<string> {
    return of(true).pipe(
      switchMap(() => {
        this.setPaymentTypeMethod(methodType);

        if (this.getPaymentPublicKeyFromStore() === null) {
          return this.getPaymentPublicKeyFormApi(methodType).pipe(map((data) => data.publicKey));
        } else {
          return of(this.getPaymentPublicKeyFromStore());
        }
      }),
    );
  }

  public getPaymentPublicKeyFormApi(methodType: PaymentTypeMethods): Observable<IPaymentPublicKeyResponse> {
    return this._httpPaymentsService.getPaymentPublicKey(methodType).pipe(
      map((data) => this._paymentsHttpAdapter.transformToPaymentPublicKeyResponse(data)),
      tap((data) => {
        this.setPaymentTypeMethod(data.type as PaymentTypeMethods);
        if (data.type === PaymentTypeMethods.stripe) {
          this._stripePaymentService.setStripePublicKey(data.publicKey);
        }
      }),
    );
  }

  public clearPaymentData(): void {
    if (this.getPaymentTypeMethodAsValue() === PaymentTypeMethods.stripe) {
      this._stripePaymentService.clearStripePaymentData();
    }
  }

  public createStripePaymentMethod(paymentData: PaymentMethod): Observable<IPaymentMethodResponse> {
    return this._httpPaymentsService.createStripePaymentMethod(paymentData).pipe(
      map((res) => this._paymentsHttpAdapter.transformToPaymentMethodResponse(res)),
      tap((data) => this._stripePaymentService.setStripePaymentMethodData(data)),
    );
  }

  public getStripePaymentMethod(): Observable<IPaymentMethodResponse> {
    return this._stripePaymentService.getStripePaymentMethodData$().pipe(
      switchMap((data) => {
        if (data === null) {
          return this.getStripePaymentMethodFromApi();
        } else return this._stripePaymentService.getStripePaymentMethodData$();
      }),
    );
  }

  public getStripePaymentMethodFromApi(): Observable<IPaymentMethodResponse> {
    return this._httpPaymentsService.getStripePaymentMethod().pipe(
      map((res) => {
        if (Utils.isArray(res)) {
          return undefined;
        } else {
          return this._paymentsHttpAdapter.transformToPaymentMethodResponse(res);
        }
      }),
      tap((data) => this._stripePaymentService.setStripePaymentMethodData(data)),
    );
  }

  public checkout(paymentId: string, total: string): Observable<ITransaction> {
    return this._httpPaymentsService.checkout(paymentId, total).pipe(
      map((res) => this._paymentsHttpAdapter.transformTransactionResponse(res)),
      tap((data) =>
        this._stripePaymentService.setStripePaymentMethodData({
          ...data.paymentMethod,
          lastPendingTransaction: {
            id: data.id,
            status: data.status,
            paymentDate: data.paymentDate,
            amount: data.amount,
          },
        }),
      ),
    );
  }

  public getLastTransaction(): Observable<ITransaction> {
    return this._httpPaymentsService
      .getLastTransaction()
      .pipe(map((res) => this._paymentsHttpAdapter.transformTransactionResponse(res)));
  }

  public createSubscription(transactionId: string): Observable<IPaymentSubscriptions> {
    return this._httpPaymentsService.createSubscription(transactionId).pipe(
      map((res) => this._paymentsHttpAdapter.transformPaymentSubscription(res)),
      tap((subscriptions) => {
        const paymentMethod = this._stripePaymentService.getStripePaymentMethodDataAsValue();
        this._stripePaymentService.setStripePaymentMethodData({
          ...paymentMethod,
          subscriptions,
        });
      }),
    );
  }

  public discardSubscription(transactionId: string): Observable<void> {
    return this._httpPaymentsService.discardSubscription(transactionId).pipe(
      tap(() => {
        const paymentMethod = this._stripePaymentService.getStripePaymentMethodDataAsValue();
        this._stripePaymentService.setStripePaymentMethodData({
          ...paymentMethod,
          subscriptions: null,
        });
      }),
    );
  }
}
