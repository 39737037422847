import { Injectable } from '@angular/core';
import { IPaymentMethodResponseApi } from '@sp-core/models/API-models/payment-method-response-api.interface';
import { IPaymentMethodResponse } from '@sp-core/models/app-models/payment-method-response.interface';
import { IPendingTransactionApi } from '@sp-core/models/API-models/pending-transaction-api.interface';
import { IPendingTransaction } from '@sp-core/models/app-models/pending-transaction.interface';
import { ITransactionApi } from '@sp-core/models/API-models/transaction-api.interface';
import { ITransaction } from '@sp-core/models/app-models/transaction.interface';
import { IPaymentSubscriptionsApi } from '@sp-core/models/API-models/payment-subscriptions-api.interface';
import { IPaymentSubscriptions } from '@sp-core/models/app-models/payment-subscriptions.interface';

@Injectable({
  providedIn: 'root',
})
export class PaymentsHttpAdapter {
  public transformToPaymentPublicKeyResponse(data: IPaymentPublicKeyResponseApi): IPaymentPublicKeyResponse {
    return {
      type: data.type,
      publicKey: data.public_key,
    };
  }

  public transformToPaymentMethodResponse(data: IPaymentMethodResponseApi): IPaymentMethodResponse {
    return {
      id: data.id,
      type: data.type,
      isHasPendingTransactions: data.is_has_pending_transactions,
      data: data.data,
      lastPendingTransaction: data?.last_pending_transaction
        ? this.transformToLastPendingTransactionResponse(data.last_pending_transaction)
        : null,
      subscriptions: this.transformPaymentSubscription(data?.subscriptions),
    };
  }

  public transformToLastPendingTransactionResponse(data: IPendingTransactionApi): IPendingTransaction {
    return {
      id: data.id,
      status: data.status,
      paymentDate: data.payment_date,
      amount: data.amount,
    };
  }

  public transformTransactionResponse(data: ITransactionApi): ITransaction {
    if (!data) return null;
    return {
      id: data.id,
      status: data.status,
      paymentDate: data.payment_date,
      amount: data.amount,
      paymentMethod: this.transformToPaymentMethodResponse(data.payment_method),
    };
  }

  public transformPaymentSubscription(data: IPaymentSubscriptionsApi): IPaymentSubscriptions {
    if (!data) return null;
    return {
      id: data.id,
      createdAt: data.created_at,
      nextPaymentDate: data.next_payment_date,
      amount: data.amount,
      status: data.status,
    };
  }
}
