import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import { IPaymentMethodResponse } from '@sp-core/models/app-models/payment-method-response.interface';

@Injectable({
  providedIn: 'root',
})
export class StripePaymentService {
  public stripePublicKey$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public stripePaymentMethodData$: BehaviorSubject<IPaymentMethodResponse> =
    new BehaviorSubject<IPaymentMethodResponse>(null);

  public setStripePublicKey(key: string): void {
    this.stripePublicKey$.next(key);
  }

  public getStripePublicKey$(): Observable<string> {
    return this.stripePublicKey$.asObservable();
  }

  public getStripePublicKeyAsValue(): string {
    return this.stripePublicKey$.getValue();
  }

  public clearStripePaymentData(): void {
    this.setStripePublicKey(null);
    this.setStripePaymentMethodData(null);
  }

  public setStripePaymentMethodData(data: IPaymentMethodResponse): void {
    this.stripePaymentMethodData$.next(data);
  }

  public getStripePaymentMethodData$(): Observable<IPaymentMethodResponse> {
    return this.stripePaymentMethodData$.asObservable();
  }

  public getStripePaymentMethodDataAsValue(): IPaymentMethodResponse {
    return this.stripePaymentMethodData$.getValue();
  }

  public getCardOptions(): StripeCardElementOptions {
    return {
      style: {
        base: {
          color: '#212125',
          fontSize: '16px',
          fontWeight: '400',
          fontFamily: '"Rubik", sans-serif',
          letterSpacing: '0.5px',
          '::placeholder': {
            color: '#B8B8B9',
          },
        },
      },
    };
  }
}
