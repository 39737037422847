import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { PatentPopupComponent } from '@sp-shared/components/footer/containers/patent-popup/patent-popup.component';
import { UserService } from '@sp-core-services';

@Component({
  selector: 'sp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit, OnDestroy {
  public destroy$: Subject<void> = new Subject();
  public currentUrl = '';
  public hideDisclaimerRoute = 'patents';
  public user: IUser;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,
    public userService: UserService,
  ) {}

  public ngOnInit(): void {
    this.initUser();
    this.subscribeRouter();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public includeRoute(link?: string): boolean {
    const linkParams: string = link || this.hideDisclaimerRoute;
    return this.router.url ? !this.currentUrl.includes(linkParams) : false;
  }

  public openDialogPatent(): void {
    this.dialog.open(PatentPopupComponent, {});
  }

  public initUser(): void {
    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });
  }

  private subscribeRouter(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe((event: NavigationEnd) => {
        this.currentUrl = event.url;
        this.cd.detectChanges();
      });
  }
}
