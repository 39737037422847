/* eslint-disable no-prototype-builtins */
import _isEqual from 'lodash.isequal';
import _cloneDeep from 'lodash.clonedeep';
import _omitBy from 'lodash.omitby';
import _isNull from 'lodash.isnull';
import _isUndefined from 'lodash.isundefined';
import _sortBy from 'lodash.sortby';
import { IUploadFile } from '@sp-core/models/app-models/upload-file.interface';

export default class Utils {
  public static isObject(item): boolean {
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  public static isArray(item): boolean {
    return item && Array.isArray(item);
  }

  public static isEmpty(obj): boolean {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  public static isEqual(value: any, other: any): boolean {
    return _isEqual(value, other);
  }

  public static sortBy(arr: any[], properties?: string[]): any {
    return _sortBy(arr, properties);
  }

  public static removePropFromObject(rawObj: { [key: string]: any }, props: string[]): { [key: string]: any } {
    return Object.keys(rawObj)
      .filter((key) => !props.includes(key))
      .reduce((obj, key) => {
        obj[key] = rawObj[key];
        return obj;
      }, {});
  }

  public static splitArrayOnPieces(arr: any[], col: number): any[][] | [] {
    if (!arr || arr.length === 0 || !col) {
      return [];
    }
    const { length } = arr;
    const resLength = Math.ceil(length / col);
    let end = resLength;
    const res: any[][] = [];
    let start = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < col; i++) {
      res.push([arr.slice(start, end)]);
      start += resLength;
      end = resLength + end;
    }
    return res;
  }

  public static copyByJSON(data: any): any {
    return JSON.parse(JSON.stringify(data));
  }

  public static deepCopy(data: any): any {
    return _cloneDeep(data);
  }

  public static getUrlParams(search) {
    const hashes = search.slice(search.indexOf('?') + 1).split('&');
    return hashes.reduce((params, hash) => {
      const [key, val] = hash.split('=');
      return Object.assign(params, { [key]: decodeURIComponent(val) });
    }, {});
  }

  public static removeNullAndUndefinedPropertiesFromObj(obj: any): any {
    const res = _omitBy(obj, _isNull);
    return _omitBy(res, _isUndefined);
  }

  public static replaceContentIdToAwsLink(attachments: IAwsUpload[], content: string): string {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(content, 'text/html');
    attachments.forEach((file) => {
      Array.from(htmlDoc.querySelectorAll('img')).forEach((img) => {
        if (img.src.includes(file.id)) {
          img.src = file.awsLink;
        }
      });
    });
    return `${htmlDoc.body.innerHTML}`;
  }

  public static addYear(date: Date, year: number): Date {
    date.setFullYear(date.getFullYear() + year);
    return date;
  }

  public static addFloatNumberDecimals(num: number, count: number): string {
    if (!num) return '';
    const isInteger: boolean = Number.isInteger(num);
    if (isInteger && count === 2) {
      return `${num}.00`;
    }
    if (isInteger && count === 3) {
      return `${num}.000`;
    }
    return num?.toFixed(count);
  }
}
