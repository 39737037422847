import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NoAuthGuard } from '@sp-core/guards/no-auth.guard';
import { AuthGuard } from '@sp-core/guards/auth.guard';
import { UserPermissions } from '@sp-core/dictionary-types/user-permissions.enum';
import { CalcGuard } from '@sp-core/guards/calc.guard';
import { SignUpComponent } from './auth/containers/sign-up/sign-up.component';
import { SignInComponent } from './auth/containers/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './auth/containers/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/containers/reset-password/reset-password.component';
import { ChangePasswordComponent } from './auth/containers/change-password/change-password.component';
import { RoleAndPermissionLimitedPracticeAdminGuard } from '@sp-core/guards/role-and-permission-limited-practice-admin.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/contact-us',
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'sign-in',
    component: SignInComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./practice/practice.module').then((mod) => mod.PracticeModule),
  },
  {
    path: 'admin/practice',
    loadChildren: () => import('./provider/provider.module').then((mod) => mod.ProviderModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.employeesOwn] },
  },
  {
    path: 'admin/practices/:practiceId',
    loadChildren: () => import('./provider/provider.module').then((mod) => mod.ProviderModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.employeesList] },
  },
  {
    path: 'admin/reports',
    loadChildren: () => import('./admin-reports/admin-reports.module').then((mod) => mod.AdminReportsModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.reportsList] },
  },
  {
    path: 'admin/activities',
    loadChildren: () => import('./admin-activities/admin-activities.module').then((mod) => mod.AdminActivitiesModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.activityLogList] },
  },
  {
    path: 'admin/roles',
    loadChildren: () =>
      import('./admin-roles-permission/admin-roles-permission.module').then((mod) => mod.AdminRolesPermissionModule),
    canActivate: [AuthGuard, RoleAndPermissionLimitedPracticeAdminGuard],
    data: { permissions: [UserPermissions.rolesAndPermissions] },
  },
  {
    path: 'admin/referrals',
    loadChildren: () => import('./referrals/referrals.module').then((mod) => mod.ReferralsModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.referralsAssign, UserPermissions.referralsContact] },
  },
  {
    path: 'admin/consult',
    loadChildren: () =>
      import('./admin-patient-consult/admin-patient-consult.module').then((mod) => mod.AdminPatientConsultModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.consultationList] },
  },
  {
    path: 'admin/users',
    loadChildren: () => import('./admin-users/admin-users.module').then((mod) => mod.AdminUsersModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.employeesList] },
  },
  {
    path: 'admin/announcements',
    loadChildren: () =>
      import('./admin-announcements/admin-announcements.module').then((mod) => mod.AdminAnnouncementsModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.announcementsCreate, UserPermissions.announcementsStatusUpdate] },
  },
  {
    path: 'admin/release-notes',
    loadChildren: () => import('./admin-resources/admin-resources.module').then((mod) => mod.AdminResourcesModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.resourcesCreate, UserPermissions.resourcesUpdate] },
  },
  {
    path: 'admin/knowledge-base',
    loadChildren: () =>
      import('./admin-knowledge-base/admin-knowledge-base.module').then((mod) => mod.AdminKnowledgeBaseModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.knowledgeBaseCategoryCreate, UserPermissions.knowledgeBaseArticleCreate] },
  },
  {
    path: 'admin/patient-forms',
    loadChildren: () =>
      import('./admin-patient-forms/admin-patient-forms.module').then((mod) => mod.AdminPatientFormsModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.patientFormsCreate, UserPermissions.patientFormsUpdate] },
  },
  {
    path: 'admin/pharmacies',
    loadChildren: () => import('./pharmacy/pharmacy.module').then((mod) => mod.PharmacyModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.pharmacyList, UserPermissions.pharmacyCreate] },
  },
  {
    path: 'patient-forms',
    loadChildren: () => import('./patient-forms/patient-forms.module').then((mod) => mod.PatientFormsModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.patientFormsList] },
  },
  {
    path: 'release-notes',
    loadChildren: () => import('./resources/resources.module').then((mod) => mod.ResourcesModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.resourcesList] },
  },
  {
    path: 'announcements',
    loadChildren: () => import('./announcements/announcements.module').then((mod) => mod.AnnouncementsModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.announcementsHistoryList] },
  },
  {
    path: 'patients',
    loadChildren: () => import('./patient/patient.module').then((mod) => mod.PatientModule),
    canActivate: [AuthGuard],
    data: {
      permissions: [UserPermissions.patientInfo],
    },
  },
  {
    path: 'patients/:patientId/site-notes/:siteNoteId',
    loadChildren: () => import('./site-note/site-note.module').then((mod) => mod.SiteNoteModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.siteNoteManage] },
  },
  {
    path: 'patients/:patientId/site-notes/:siteNoteId/view',
    loadChildren: () => import('./site-note/site-note.module').then((mod) => mod.SiteNoteModule),
    canActivate: [AuthGuard],
    data: {
      onlyView: true,
      permissions: [UserPermissions.siteNoteGet],
    },
  },
  {
    path: 'patients/:patientId/calculations/:calculationsId',
    loadChildren: () => import('./injection-calc/injection-calc.module').then((mod) => mod.InjectionCalcModule),
    canActivate: [AuthGuard, CalcGuard],
    data: { permissions: [UserPermissions.calculation] },
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((mod) => mod.HomeModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.dashboard] },
  },
  {
    path: 'inventory',
    loadChildren: () => import('./inventory/inventory.module').then((mod) => mod.InventoryModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.inventory] },
  },
  {
    path: 'insertion-note-templates',
    loadChildren: () =>
      import('./insertion-note-templates/insertion-note-templates.module').then(
        (mod) => mod.InsertionNoteTemplatesModule,
      ),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.insertionNoteTemplates, UserPermissions.insertionNoteTemplatesOwn] },
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./terms-conditions/terms-conditions.module').then((mod) => mod.TermsConditionsModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.termsGet] },
  },
  {
    path: 'cerbo/link_patient',
    loadChildren: () => import('./cerbo/cerbo.module').then((mod) => mod.CerboModule),
    canActivate: [AuthGuard],
    data: {
      permissions: [UserPermissions.cerboImport],
    },
  },
  {
    path: 'cerbo/integrations',
    loadChildren: () =>
      import('./cerbo-integrations/cerbo-integrations.module').then((mod) => mod.CerboIntegrationsModule),
    canActivate: [AuthGuard],
    data: {
      permissions: [UserPermissions.cerboIntegrations],
    },
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then((mod) => mod.ContactUsModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then((mod) => mod.SettingsModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.profileGet] },
  },
  {
    path: 'consult',
    loadChildren: () => import('./consult/consult.module').then((mod) => mod.ConsultModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.userConsultRequests, UserPermissions.userConsultForm] },
  },
  {
    path: 'medical-questions',
    loadChildren: () =>
      import('./medical-questions/medical-questions.module').then((mod) => mod.MedicalQuestionsModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.medicalQuestionsList] },
  },
  {
    path: 'admin/medical-questions',
    loadChildren: () =>
      import('./admin-medical-questions/admin-medical-questions.module').then((mod) => mod.AdminMedicalQuestionsModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.medicalQuestionsAssign] },
  },
  {
    path: 'admin/dashboard',
    loadChildren: () =>
      import('./practice-admin-dashboard/practice-admin-dashboard.module').then(
        (mod) => mod.PracticeAdminDashboardModule,
      ),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.practiceOwn] },
  },
  {
    path: 'simple-calculator',
    loadChildren: () =>
      import('./simple-calculator/simple-calculator.module').then((mod) => mod.SimpleCalculatorModule),
    canActivate: [AuthGuard],
    data: { permissions: [UserPermissions.simpleCalculation] },
  },
  {
    path: 'knowledge-base',
    loadChildren: () => import('./knowledge-base/knowledge-base.module').then((mod) => mod.KnowledgeBaseModule),
    canActivate: [AuthGuard],
    data: {
      permissions: [
        UserPermissions.knowledgeBaseCategoryList,
        UserPermissions.knowledgeBaseArticleList,
        UserPermissions.knowledgeBaseUserAccess,
      ],
    },
  },
  // {
  //   path: 'membership-fees',
  //   loadChildren: () => import('./membership-fees/membership-fees.module').then((mod) => mod.MembershipFeesModule),
  //   canActivate: [AuthGuard],
  //   data: {
  //     permissions: [UserPermissions.paymentCheckout],
  //   },
  // },
  {
    path: 'admin',
    redirectTo: 'admin/practices',
  },
  {
    path: 'admin/blocked',
    redirectTo: 'forgot-password',
  },
  {
    path: '**',
    redirectTo: 'contact-us',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
