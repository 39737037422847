import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import type { IInfoPopupData } from '../../interfaces/info-popup-data-interface';

@Component({
  selector: 'sp-info-popup',
  templateUrl: './info-popup.component.html',
  styleUrls: ['./info-popup.component.scss'],
})
export class InfoPopupComponent {
  constructor(
    @Optional() public dialogRef: MatDialogRef<InfoPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: IInfoPopupData,
  ) {
    this.data = {
      isCloseBtn: true,
      ...this.data,
      isTranslateTextField: this.data?.isTranslateTextField ?? true,
    };
  }

  public clickClose(confirm: boolean): void {
    this.dialogRef.close(confirm);
  }
}
